.nav-tabs .nav-item {
  padding-right: 10px !important;
}
.tab-card ul li {
  margin-top: 10px;
}
.card-header-tabs {
  margin-left: 0px !important;
}
.landing .side-table thead th {
  border-bottom: 0.3px solid #1485ff !important;
  border-top: 0.3px solid #1485ff !important;
  padding: 10px 5px 10px 5px !important;
  background-color: #f0f7ff;
}
.landing .side-table tbody td {
  border-bottom: 0.3px solid #1485ff;
  padding: 10px 5px 10px 5px !important;
}
.landing .side-table tbody td:nth-child(n + 2) {
  text-align: right;
}
.landing .side-table thead th:nth-child(n + 2) {
  text-align: right;
}
.landing .side-table {
  font-style: normal;
  font-weight: 400;
  font-size: 11.6814px;
  line-height: 106%;
  color: #2e2e2e;
  margin-bottom: 0px;
}
.border_line {
  border-bottom: 1px solid #1485ff;
}
.main_toping {
  max-height: 280px;
  overflow: hidden;
}
.main_toping:hover {
  overflow-y: scroll !important;
}
.main_toping::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
.main_toping::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #6d6d6d;
}
.landing h2 {
  height: 21px;
  left: 950px;
  top: 215px;
  /* margin-top: 20px;   */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 106%;
  color: #1d4283;
}
.landing .chart hr {
  background-color: #1485ff;
  margin: 0px;
}
.landing .researchitem {
  padding: 18px 0px 18px 0px;
}
.landing .researchitem .icon {
  position: absolute !important;
  padding: 0px;
}

.Tab_learn {
  margin-top: 15px;
}
.landing .researchitem .heading {
  /* margin-left: 22px !important; */
  font-weight: 700;
  font-size: 15px;
  padding: 0px !important;
  color: #000 !important;
  background: none !important;
  line-height: 125.5%;
}
.indices-chart {
  margin-top: 20px;
}
.main_luckest {
  display: flex;
  align-items: center;
}
.blog-thumnail {
  position: absolute;
}
.landing .researchitem .latest-heading {
  padding-left: 44px;
  font-weight: 700;
  font-size: 15px;
  color: #000 !important;
  background: none !important;
  line-height: 125.5%;
}
.landing .researchitem .blogheading {
  font-weight: 700;
  padding-top: 11px;
  font-size: 12px;
  line-height: 125.5%;
}
.landing .researchitem .blogcontent {
  padding-left: 15px;
  padding-right: 0px;
  width: 94%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 143.5%;
}
.researchitem a {
  font-style: normal;
  color: #1d4283;
  text-decoration: underline;
}
.main_row {
  display: flex;
  align-items: center;
  gap: 5px;
}
.landing .researchitem .blogitem {
  background: #fafafa;
  position: relative;
  border-radius: 0px;
  margin-left: 70px;
  margin-top: 8px;
  padding-bottom: 17px;
}
.landing .researchitem .content {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143.5%;
}
.landing .researchitem .calander {
  padding-left: 0px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 125.5%;
  gap: 2px;
  color: #1d4283;
}
.top_advance {
  margin-top: 30px;
}
.landing .chart-row-one {
  margin-bottom: 30px;
  margin-top: 14px;
}
.landing .chart-row-two {
  margin-bottom: 30px;
  margin-top: 50px;
}
.landing .indices .heading {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 106%;
  background: none !important;
  color: #2e2e2e;
  padding: 0px;
  margin-bottom: 2px;
}
.landing .indices .heading.number {
  font-size: 20px;
}
.landing .indices .indices-border-bottom {
  border-bottom: 1px solid #1485ff;
  display: flex;
  flex-wrap: unset;
}
.landing .indices .col-4 {
  padding-left: 0px;
}
.landing .indices .text-color-red {
  color: #c94545 !important;
}
.landing .indices .text-color-green {
  color: #18b715 !important;
}
.landing .indices .row {
  margin-bottom: 5px;
  padding-bottom: 10px;
}
.landing .indices .content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 106%;
  color: #6c6c6c;
}
.landing .side-table thead tr {
  top: -1px;
  position: sticky;
}
.research_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .research_view button{
    border: none;
    outline: none;
    background: none;
    color: #1D4283;
    font-size: 12px;
    font-weight: 400;
  } */
.research_view a {
  text-decoration: underline;
  color: #1d4283 !important;
  border: none;
  outline: none;
  background: none;
  color: #1d4283;
  font-size: 12px;
  font-weight: 400;
}
#arrow-up {
  color: #18b715 !important;
  width: 25%;
}
#arrow-down {
  color: #c94545 !important;
  width: 26%;
}
.learn_icon {
  padding: 0px;
  display: flex;
}
.latest_more {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 1px;
}
.tab-card-header .mantine-fq0g4p span {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  font-size: 12px;
}
.tab-card-header .mantine-fq0g4p [role='tab'] {
  background: #f0f7ff;
  border: 1px solid #1485ff;
  border-radius: 0px;
  width: 15%;
}
.tab-card-header .mantine-1hher0m[data-active] {
  background-color: #1d4283 !important;
  color: #fff;
  border: none;
  border-radius: 0px;
}
.tab-card-header .mantine-fq0g4p {
  border: none !important;
  margin-top: 20px;
  justify-content: start !important;
  gap: 5px !important;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.briefing-context h1 {
  color: #1d4283;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #1485ff;
  line-height: 106%; /* 21.022px */
}
.briefing-pdf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1485ff;
  padding: 6px 0rem;
}
.market_briefing {
  margin: 50px 0px;
}
.net_boxed h1 {
  color: #1d4283;
  font-size: 19.832px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%; /* 21.022px */
}
.negative-sign {
  color: #c94545 !important;
}
.positive-sign {
  color: #18b715 !important;
}

.sector_sizing {
  margin-top: 40px;
}

.briefing-pdf span {
  font-size: 15px;
}

.market_round {
  margin-top: 30px;
}

.green-text {
  color: green !important;
}

.red-text {
  color: red !important;
}
.incides-text {
  text-align: right;
}

.spark-chart img {
  width: -webkit-fill-available;
  height: 60px;
}
@media screen and (max-width: 600px) {
  .landing {
    padding: 0rem 1rem;
  }
  .landing .chart-row-two {
    margin-top: 28px;
    margin-bottom: 0px;
  }
  .landing h2 {
    margin-top: 12px;
  }
  .landing .side-table {
    margin-bottom: 30px;
  }
  .card-header-tabs {
    justify-content: space-between;
  }
  .landing .researchitem .blogheading {
    padding-top: 6px;
    font-size: 9px;
  }
  .landing .researchitem .blogcontent {
    width: -webkit-fill-available;
    font-size: 9px;
  }
  .landing .researchitem .calander {
    font-size: 9px;
  }
  .top_active {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .top_active_display {
    display: none;
  }
}



/* .technical-block{
    font-size: 18px;
    border: 0.25px solid rgb(57 109 213 / 75%);
    border-radius: 5px;
    margin: 10px auto 0;
    width: 100%;
    display: inline-block;
}
.technical-block .title-block h4{
    color: #484848;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 0.1em solid rgb(57 109 213 / 75%);
    padding: 12px;
    margin: 0;
    text-transform: capitalize;
}
.dashboard-main.dark .technical-block .title-block h4{
    color: #ffffff;
}
.technical-block{
    overflow: hidden;
}
.technical-block .data-block{
    background: #ffffff;
}
.technical-block .data-block .technical-chart{
    min-height: 370px;
    width: 100%;
}
.technical-block .data-block .technical-chart canvas{
    font-size: 10px;
} */
.comp-financial-block {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px 0 0;
  width: 100%;
  display: inline-block;
}

.overview-main .time-line-block {
  padding-top: 25px !important;
}

.w-100.li-heading {
  border-right: unset;
}

.comp-financial-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;

  text-transform: capitalize;
  border-left: none;
}

.dashboard-main.dark .comp-financial-block .title-block h4 {
  color: #ffffff;
}
.comp-financial-block .data-block .comp-financials {
  width: 100%;
  overflow: auto;
}
.comp-financial-block .data-block table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.comp-financial-block .data-block table thead tr th {
  border-top: 0.3px solid var(--light-border-color);
  /* border-right: 0.3px solid var(--light-border-color); */
  border-collapse: collapse;
  border-bottom: 0.3px solid var(--light-border-color);
  color: var(--table-heading-color);
  font-size: var(--table-heading-font-size);
  font-weight: var(--table-heading-weight);
  text-align: var(--table-heading-text-align);
  padding: 6px;
}
.comp-financial-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.dashboard-main.dark .comp-financial-block .data-block table thead tr th {
  color: #ffffff;
}
.comp-financial-block .data-block table tbody tr td {
  border-top: 0.3px solid var(--light-border-color);
  border-bottom: 0.3px solid var(--light-border-color);
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  text-align: var(--table-cell-text-align);
  font-weight: var(--table-cell-weight);
  padding: var(--table-cell-inner-space);
  /* height: 35px; */
  vertical-align: middle;
  color: var(--table-cell-color);
}
.dashboard-main.dark .comp-financial-block .data-block table tbody tr td {
  color: #ffffff;
}

/* .comp-financial-block .data-block table thead tr th{
  font-weight: 700;
  color: #484848;
  padding: 12px 0.75rem;
  border: 0.25px solid rgb(57 109 213 / 75%);

} */

.comp-financial-block .data-block table tbody tr td:first-child {
  text-align: var(--table-label-column-text-align);
  font-size: var(--table-label-column-font-size);

  font-weight: var(--table-label-column-font-weight);
  color: var(--table-label-column-color);
  border-left: none;
}
.comp-financial-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.comp-financial-block .data-block table tbody tr:last-child td {
  border-bottom: 0px;
}
.comp-financial-block .data-block table tbody tr td:last-child {
  border-right: 0px;
}
.industry-avg-block {
  font-size: 18px;
  /* border: 0.25px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px 0 0;
  width: 100%;
  display: inline-block;
}
.industry-avg-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  /* border-bottom: 1px solid var(--sharp-border-color); */
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
  border-left: none;
}
.dashboard-main.dark .industry-avg-block .title-block h4 {
  color: #ffffff;
}
.industry-avg-block .data-block .industry-avg {
  width: 100%;
  height: 100%;
}
.industry-avg-block .data-block table {
  margin: 0;
  display: inline-table;
  border-collapse: collapse;
  border: none;
}
.industry-avg-block .data-block table thead tr th {
  border-top: 0.25px solid var(--light-border-color) !important;
  /* border-right: 0.25px solid #3969D585 !important; */
  border-collapse: collapse;
  border-bottom: 0.25px solid var(--light-border-color) !important;
  font-size: var(--table-heading-font-size);
  font-weight: var(--table-heading-weight);
  text-align: var(--table-heading-text-align);
  /* height: 36px; */
  padding: var(--table-heading-inner-space);
}
.industry-avg-block .data-block table thead tr th:last-child {
  border-right: 0px;
}
.industry-avg-block .data-block table tbody tr td {
  border-top: 0px;
  border-bottom: 0.25px solid var(--light-border-color) !important;
  border-collapse: collapse;
  font-size: var(--table-cell-font-size);
  font-weight: var(--table-cell-weigt);
  text-align: var(--table-cell-text-align);
  padding: var(--table-cell-inner-space);
  vertical-align: middle;
  color: var(--table-cell-color);
}
.dashboard-main.dark .industry-avg-block .data-block table tbody tr td {
  color: #ffffff;
}
.industry-avg-block .data-block table tbody tr:last-child td {
  border-bottom: 0px !important;
}
.industry-avg-block .data-block table tbody tr td:first-child {
  border-top: 0px;
  /* border: 0.25px solid rgb(57 109 213 / 75%); */
  border-collapse: collapse;
  font-size: var(--table-label-column-font-size);
  text-align: var(--table-label-column-text-align);
  font-weight: var(--table-label-column-weight);
  padding: 6px;
  /* height: 35px; */
  vertical-align: middle;
  color: var(--table-label-column-color);
  border-right: none;
}

/* .industry-avg-block .data-block table tbody tr{
    border: 0.25px solid rgb(57 109 213 / 75%);
} */

.industry-avg-block .data-block table tbody tr td:last-child {
  border-right: 0px;
  text-align: right;
  font-weight: var(--table-cell-weight);
}
.settlement-block {
  font-size: 18px;
  /* border-bottom: 0.3px solid var(--light-border-color); */
  border-top: 0.3px solid var(--light-border-color);
  /* border-radius: 5px; */
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.settlement-block .title-block {
  max-height: 50px;
}
.settlement-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .settlement-block {
  background-color: #fff;
}
/* .dashboard-main.dark .settlement-block .title-block{
    background: #222222;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
} */
.dashboard-main.dark .settlement-block .title-block h4 {
  color: var(--table-heading-block-color);
}
.settlement-block .data-block {
  height: calc(100% - 50px);
  background-color: #ffffff;
}
.settlement-block .data-block .settlement-chart {
  min-height: 205px;
  width: 100%;
}
.settlement-block .data-block .settlement-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.ffl-block {
  font-size: 18px;
  /* border: 0.3px solid var(--light-border-color); */
  border-radius: 5px;
  margin: 25px auto 0;
  width: 100%;
  display: inline-block;
}
.ffl-block .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 0.3px solid var(--sharp-border-color);
  border-top: 0.3px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
  /* height: 32px; */
  /* line-height: 1; */
}
.dashboard-main.dark .ffl-block {
  background-color: #ffffff;
}
/* .dashboard-main.dark .ffl-block .title-block{
    background: #222222;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
} */
.dashboard-main.dark .ffl-block .title-block h4 {
  color: var(--table-heading-block-color);
}
.dashboard-main.dark .ffl-block .data-block {
  background: #ffffff;
}
.ffl-block .data-block .ffls-chart {
  min-height: 440px;
  width: 100%;
}
.ffl-block .data-block .ffls-chart canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.cirdci-block .col-lg-4 {
  max-height: 600px;
  min-height: 600px;
}
.cirdci-block .company-intro {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 10px auto 0;
  width: 100%;
  /* height: 600px; */
  height: 100%;
  overflow-y: hidden;
  display: inline-block;
}
.cirdci-block .company-intro .title-block {
  display: inline-block;
  width: 100%;
  height: 38px;
}
.cirdci-block .company-intro .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid var(--sharp-border-color);
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .cirdci-block .company-intro .title-block h4 {
  color: #ffffff;
}
.cirdci-block .company-intro .data-block {
  padding: 15px;
  height: calc(100% - 38px);
  overflow: hidden;
  font-size: 14px;
  color: var(--table-cell-color);
}
.cirdci-block .company-intro .data-block blockquote p::before {
  content: '\201C';
}
.cirdci-block .company-intro .data-block blockquote p::after {
  content: '\201D';
}
.cirdci-block .company-intro .data-block h6 {
  color: var(--table-heading-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0px auto 15px;
  /* padding-left: 10px; */
}
.dashboard-main.dark .cirdci-block .company-intro .data-block h6 {
  color: #ffffff;
  margin-top: 0;
}
.cirdci-block .company-intro .data-block p {
  color: var(--light-black);
  font-size: 14px;
  font-weight: 400;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block p {
  color: #ffffff;
  margin-top: 0 !important;
}
.cirdci-block .company-intro .data-block b {
  color: var(--table-heading-color);
  font-size: 14px;
  font-weight: 700;
  margin: 15px auto;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block b {
  color: #ffffff;
}
.cirdci-block .company-intro .data-block ul {
  padding-left: 15px;
  margin-bottom: 30px !important;
  list-style: none;
}
.cirdci-block .company-intro .data-block ul li {
  position: relative;
}
.cirdci-block .company-intro .data-block ul li:before {
  position: absolute;
  content: '';
  left: -10px;
  top: 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #484848;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block ul li:before {
  background-color: #ffffff;
}
.cirdci-block .company-intro .data-block ul li p {
  color: var(--table-cell-color);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.dashboard-main.dark .cirdci-block .company-intro .data-block ul li p {
  color: #ffffff;
  margin-bottom: 10px;
}
.financial-chart-block .financial-chart {
  font-size: 18px;
  /* border: 0.25px solid #3969D585; */
  border-radius: 5px;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
/* .financial-chart-block .financial-chart > .title-block{
    margin-bottom: 5px;
} */
.financial-chart-block .financial-chart > .title-block h4 {
  color: var(--table-heading-block-color);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-radius: 5px 5px 0 0;
  /* border: 0.25px solid var(--light-border-color); */
  /* border-bottom: 1px solid var(--sharp-border-color); */
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .financial-chart-block .financial-chart > .title-block h4 {
  color: #ffffff;
}
.financial-chart-block .data-block .chart-title {
  text-align: center;
  margin: 10px auto;
  /* text-transform: capitalize; */
  color: var(--light-black);
  font-size: 12px;
  font-weight: 500;
  min-height: 21px;
}
.dashboard-main.dark .financial-chart-block .data-block .chart-title {
  color: #ffffff;
}
.financial-chart-block .data-block .chart-block canvas {
  font-size: 10px;
  /* padding: 2px; */
}
.financial-chart-block .data-block .slick-slider .slick-list .slick-slide {
  text-align: center;
  width: 273px !important;
}
.financial-chart-block .data-block .slick-slider .slick-arrow {
  background: #396dd578;
  border-radius: 5px;
  box-shadow: 0 0 5px #dfdfdf;
  z-index: 99999;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  transition: 0.2s all ease-in-out;
}
.financial-chart-block .data-block .slick-slider .slick-arrow:hover {
  opacity: 1;
  transition: 0.2s all ease-in-out;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-next {
  right: -5px;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-prev {
  left: -5px;
}
.financial-chart-block .data-block .slick-slider .slick-arrow.slick-prev:before,
.financial-chart-block
  .data-block
  .slick-slider
  .slick-arrow.slick-next:before {
  display: none;
}
/* .financial-chart-block .data-block .slick-slider .slick-arrow svg{
    position: absolute;
    left: 0;
} */
/* .dashboard-main.dark .financial-chart-block .data-block .slick-slider .slick-arrow svg path{
    fill: #ffffff;
} */
.financial-chart-block .data-block .slick-slider .slick-arrow svg path {
  /* fill: #484848; */
  fill: #ffffff;
}
.financial-chart-block .finance-income {
  font-size: 18px;
  /* border-bottom: 0.3px solid var(--light-border-color); */
  border-top: 0.3px solid var(--light-border-color);
  /* border-radius: 5px; */
  margin: 25px auto 0;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-income .title-block h4 {
  color: var(--light-black);
  font-size: var(--table-heading-block-font-size);
  font-weight: var(--table-heading-block-weight);
  border-bottom: 1px solid #1485ff;
  padding: var(--table-heading-block-inner-space);
  margin: 0;
  text-transform: capitalize;
}
.financial-chart-block .finance-income .data-block {
  padding: 0px !important;
}
.financial-chart-block .finance-income .data-block .chart-block {
  display: inline-block;
  min-width: calc(100%);
  min-height: 270px;
  /* border-radius: 5px; */
  background-color: #ffffff;
  /* border: 1px solid var(--light-border-color); */
}

.financial-chart-block .finance-balance {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 5px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-balance .title-block h4 {
  color: #484848;
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid var(--sharp-border-color);
  padding: 10px;
  margin: 0;
  text-transform: capitalize;
}
.financial-chart-block .finance-balance .data-block {
  padding: 10px !important;
  /* background: #73a9925e; */
}
.financial-chart-block .finance-balance .data-block .chart-block {
  display: inline-block;
  min-width: calc(100% - 30px);
  min-height: 300px;
  box-shadow: 0 0 10px #67b7dc;
  border-radius: 5px;
  background-color: #ffffff;
}

.financial-chart-block .finance-ratio {
  font-size: 18px;
  border: 0.25px solid var(--light-border-color);
  border-radius: 5px;
  margin: 5px auto;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.financial-chart-block .finance-ratio .title-block h4 {
  color: #484848;
  font-size: 17px;
  font-weight: 600;
  border-bottom: 1px solid var(--sharp-border-color);
  padding: 10px;
  margin: 0;
  text-transform: capitalize;
}
.dashboard-main.dark .financial-chart-block .finance-income .title-block h4 {
  color: #ffffff;
}
.dashboard-main.dark .financial-chart-block .finance-ratio .title-block h4 {
  color: #ffffff;
}
.dashboard-main.dark .financial-chart-block .finance-balance .title-block h4 {
  color: #ffffff;
}
.financial-chart-block .finance-ratio .data-block {
  padding: 10px !important;
}
.financial-chart-block .finance-ratio .data-block .chart-block {
  display: inline-block;
  min-width: calc(100% - 30px);
  min-height: 300px;
  box-shadow: 0 0 10px #67b7dc;
  border-radius: 5px;
  background-color: #ffffff;
}
@media only screen and (max-width: 991px) {
  /* .technical-block .data-block .technical-chart{
        min-height: 350px;
    } */
}

.left {
  float: left;
}

.right {
  float: right;
}
#ffls .am5exporting-menu a.am5exporting-valign-top {
  top: 3px !important;
}
#setttlement-volume-s2,
#setttlement-value-s2 {
  height: 250px !important;
  /* height: 409px !important; */
}
.chart__tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 0px 0px;
  padding-bottom: 0rem;
}
.chart__range {
  display: flex;
  gap: 10px;
}
.chartrange_item {
  padding: 5px 6px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.chartimg {
  line-height: 15rem;
}
.chart_rangeitem {
  padding: 5px 6px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.chartrange_item--selected {
  background-color: #1d4283;
  color: #fff;
}
.quote__date {
  font-size: 12px;
}

.secondsec {
  padding: 0 15px 0px 15px;
}
.secondsec img {
  float: left;
  margin-right: 12px;
}
.luckyheading {
  color: #324054;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
  margin-bottom: 0rem;
}
.luckchange {
  font-size: 12px;
  width: 78%;
  margin: revert;
  margin-bottom: 0rem;
  color: #324054;
  font-weight: 600;
}
.secondsec1 {
  padding: 0 15px 0px 15px;
}
.secondsec1 img {
  float: left;
  background: #cdd9f8;
  border-radius: 100px;
  padding: 0.25rem;
  margin-right: 12px;
}
.iconsymbol {
  position: absolute;
  margin-left: 40px;
}
.iconsymbol2 {
  margin-left: 27px;
}
.iconsymbol1 {
  position: absolute;
  margin-left: 14px;
}
.commenttext {
  margin: revert;
  color: #999999;
  margin-left: auto;
  margin-right: 43px;
  font-weight: 600;
  font-size: 12px;
}
.liketext {
  margin: revert;
  color: #747a83;
  cursor: pointer;
  font-size: 14px;
}
.searchkeypard {
  border: none;
  padding: 9px 45px 9px 13px;
  width: -webkit-fill-available;
  background: #f6f7f8;
  outline: none;
  border-radius: 10px;
}
.arrowbutton {
  padding: 11px 12px 11px 12px;
  border: none;
  margin-left: auto;
  border-radius: 8px;
}
.sharestext {
  color: #999999;
  margin: revert;
  font-weight: 600;
  font-size: 12px;
}
.publicsymbol {
  color: #71839b;
  margin-bottom: 0rem;
  font-size: 12px;
}
.datesymbol {
  font-size: 12px;
  border-radius: 2px;
  color: #3d3d3d;
  margin-right: 8px;
  padding: 3px 5px;
  background: #fee9d7;
}
.lucksymbol {
  font-size: 12px;
  color: #3d3d3d;
  padding: 3px 5px;
  margin-right: 8px;
  border-radius: 2px;
  background: #cdd9f8;
}
.recentsymbol {
  font-size: 12px;
  color: #3d3d3d;
  padding: 3px 5px;
  margin-right: 8px;
  border-radius: 2px;
  background: #d4f1e1;
}
.capexsymbol {
  font-size: 12px;
  border-radius: 2px;
  color: #3d3d3d;
  margin-right: 8px;
  padding: 3px 5px;
  background: #fdd2e6;
}
