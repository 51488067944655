.latest_heading h1 {
  color: #071335;
  font-size: 24px;
  margin-top: 40px;
  letter-spacing: -0.36px;
}

.researchtogglesec .mantine-fq0g4p{
  border: none;
}
.researchtogglesec .mantine-fq0g4p [role="tab"]{
  color: #71839B;
}
.listing_tabs{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.researchtogglesec .mantine-fq0g4p [role="tab"]:hover{
  background: none !important;
  border: none;
}
.researchtogglesec .mantine-1hher0m[data-active]{
  color: #5E92E0 !important;
  border-bottom: 1px solid #5E92E0 !important;
}
.luckbox1_img{
  width: -webkit-fill-available;
}
.report_listing{
  margin-top: 40px;
}

.underline {
  border-bottom: 2px solid blue;
}

.toggle {
  display: flex;
  align-items: center;
}

.slider.round {
  border-radius: 34px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 6px;
  left: 8px;
  right: 6px;
  bottom: 9px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.main_research {
  padding: 0px 14px;
}
.researh_bg {
  background-image: url(../../../assets/media/images/FFC-Plant-img.svg);
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  padding: 27px 0px;
}
.box_slide {
  border: 1px solid #ccc;
  background: #FBFBFB;
  width: 350px;
  height: 233px;
  padding: 24px 18px 23px 25px;
}
.cropped_icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
}
.mainlucky li {
  font-size: 10px;
  color: #71839B;
}
.luckheldtextbox {
  font-size: 18px;
  margin-top: 15px;
  width: -webkit-fill-available;
  color: #324054;
}
.fellowparagraph {
  font-size: 12px;
  width: 98%;
  margin-top: 16px;
  line-height: 14px;
  color: #71839B;
  margin-bottom: 16px;
}
.date_symbol {
  font-size: 10px;
  color: #71839B;
}
.cropped_icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
}
.luck_span {
  font-size: 10px;
  margin-left: 10px;
}
.luckheldtext1 {
  font-size: 14px;
  margin-top: 6px;
  padding: 0px;
  margin: 6px 8rem;
  width: 180px;
  margin-bottom: 6px;
}
.date_symbol1 {
  font-size: 10px;
  color: #71839B;
  margin-left: 13px;
}
.mainlucky ul {
  margin-bottom: 0px;
  padding-left: 25px;
}
.luckheldtext {
  font-size: 14px;
  margin-top: 15px;
  width: 178px;
  padding: 0px;
}
.border-bottom {
  padding-bottom: 20px;
}
.subcribe_sec h1 {
  padding: 38px 27px 8px;
  color: #071335;
  font-size: 20px;
  width: 88%;
  line-height: 22px;
}
.subcribe_sec p {
  padding: 0px 27px 12px;
  color: #71839B;
  font-size: 12px;
  line-height: 132%;
  letter-spacing: -0.36px;
  width: 87%;
}
.research_input {
  margin-left: 27px;
  width: 200px;
  border-radius: 4px;
  padding: 12px 22px 13px 22px;
  height: 34px;
  font-size: 12px;
  outline: none;
  border: none;
  border: 1px solid #D9DBE9;
  background: #FFF;
}
.sub_btn {
  margin-left: 27px;
  width: 200px;
  color: #FFF;
  border: none;
  font-size: 10px;
  letter-spacing: -0.3px;
  height: 32.141px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #5E92E0;
  margin-top: 10px;
}
.recent_sec {
  padding: 38px 27px 8px;
}
.latestbigimg {
  width: -webkit-fill-available;
}
.latestminibox {
  float: left;
  width: 118px;
}