form{
    position: relative;
}
.login-sec {
    background: #ffffff;
    height: 100vh;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 70px 30px 30px;
    width: 100%;
}          
.login-sec div.logo{
    width: auto;
    display: inline-flex;
    align-items: center;
    margin: 0px auto;
    position: absolute;
    left: 50px;
    top: 40px;
}
.login-sec div.logo span{
    font-size: 20px;
    font-family: initial;
    margin-left: 10px;
}
.login-sec .login-img{
    -webkit-animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.login-sec .reset-img img{
    width: 425px;
}
.login-title{
    text-align: left;
    font-size: 30px;
    font-size: 1.75em;
}
.login-sec .form-area{
    max-width: 300px;
    width: 300px;
    /* -webkit-animation: bounce-in-top 1.5s both;
    animation: bounce-in-top 1.5s both; */
}
.login-sec .form-area h3.reset-title{
    color: #3969d5;
    margin-bottom: 30px;
}
.login-sec .form-area form .form-group{
    margin-bottom: 25px;
    position: relative;
}
.login-sec .form-area form .form-group label{
    color: #3D3D3D;
    line-height: 1;
}
.login-sec .form-area form .form-group .form-control{
    background-color: transparent;
    border: 1px solid var(--light-border-color);
    min-height: 40px;
}
.login-sec .form-area form .form-group .form-control::placeholder{
    font-size: 15px;
    color: #A9A9A9;
}
.login-sec .form-area form .form-group span.eye-icon{
    width: 20px;
    position: absolute;
    bottom: 0;
    right: 7px;
    height: 40px;
}
.login-sec .form-area form .form-group span.eye-icon img{
    width: 100%;
    height: 100%;
}
.login-sec .form-area form button{
    color: #ffffff;
    width: 100%;
    background: #3969d5;
    min-height: 40px;
    margin-top: 30px;
}
.field-error{
    color: red;
    position: absolute;
    font-size: 12px;
}
form .forgot-password{
    margin: 0;
    position: absolute;
    line-height: 1;
    top: -15px;
    right: 0px;
    font-size: 12px;
    cursor: pointer;
}
