.ksa-content {
  margin-top: 18px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.morning-ksa svg {
  display: flex;
  margin-left: auto;
  color: rgb(57 109 213);
}
.ksa-content h1 {
  background-color: #386090;
  padding: 8px 16px;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 107%;
  letter-spacing: -0.5px;
}
.ksa-content p {
  margin-bottom: 0px;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: -0.5px;
}
.morning-text h1 {
  color: #386090;
  font-size: 33px;
  margin-bottom: 0px;
  margin-top: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; /* 51.36px */
  letter-spacing: -1.2px;
}

.morning-topic table {
  width: 100% !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
.morning-topic table tr th {
  padding-bottom: 10px;
  border-bottom: 1px solid #535250;
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 107%; /* 25.68px */
  letter-spacing: -0.6px;
}
.morning-topic table tr td {
  color: #464c53;
  font-size: 14px;
  padding-bottom: 18px;
  padding-top: 20px;
  border-bottom: 1px solid #cdcbc8;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
}
.morning-topic table tr td a {
  color: #464c53;
  text-decoration: none;
}
.morning-topic table tr td:nth-child(2) {
  text-align: right;
}
.morning-topic table tr th:nth-child(2) {
  text-align: right;
}
.economy-grey {
  color: #464c53;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0px;
}
.positive-green {
  color: #18b715;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0px;
}
.negative-red {
  color: #dd2025;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0px;
}
.neutral-black {
  color: #464c53;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0px;
}
.ksa-content span {
  color: #d9d9d9;
}

/* .ksa-popup .mantine-1go5vd0{
  top: 50px !important;
} */

.ksa-popup h2 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.upload-input {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ksa-apply-btn {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 26px;
}

.ksa-apply-btn button {
  width: -webkit-fill-available;
}

.ksa-pdf-icon {
  display: flex;
  justify-content: right;
  gap: 20px;
}

.ksa-pdf-icon a {
  cursor: pointer;
}

.set-default {
  color: #000;
}

.fill-color-picker h1 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%; /* 12.72px */
}

.main-picker-select {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 30px;
  justify-content: space-between;
}

.fill-color-picker
  :where(.css-dev-only-do-not-override-14e9oss).ant-color-picker-trigger {
  margin: auto;
}

.morning-text img {
  width: 192px;
  /* height: 66px; */
}

.morning-text {
  display: flex;
  justify-content: space-between;
}
