.second-nav {
  flex-direction: unset !important;
}
.second-nav .nav-item.active button {
  color: #ffffff;
  background: #1d4283;
  border: 0.3px solid #1485ff;
  border-radius: 2px;
}
.second-nav .nav-item {
  /* padding-left: 0.5rem !important; */
  padding-right: 0.5rem !important;
}
