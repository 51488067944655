form{
    position: relative;
}
.login-sec {
    background: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
}          
.login-sec div.logo{
    width: 100%;
    display: inline-flex;
    align-items: center;
    margin: 0px auto 30px;
}
.login-sec div.logo img{
    width: 130px;
}
.login-sec div.logo span{
    font-size: 20px;
    font-family: initial;
    margin-left: 10px;
}
.login-sec .login-img{
    -webkit-animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.login-sec .login-img img{
    max-width: 500px;
}
.login-title{
    text-align: left;
    font-size: 30px;
    font-size: 1.75em;
}
.login-sec .form-area{
    max-width: 300px;
    width: 300px;
    /* -webkit-animation: bounce-in-top 1.5s both;
    animation: bounce-in-top 1.5s both; */
}
.login-sec .form-area h3{
    margin-bottom: 50px;
}
.login-sec .form-area form .form-group{
    margin-bottom: 25px;
}
.login-sec .form-area form .form-group label{
    color: #3D3D3D;
    line-height: 1;
}
.login-sec .form-area form .form-group .form-control{
    background-color: #E5E5E5;
    border: none;
    min-height: 40px;
}
.login-sec .form-area form .form-group .text-danger{
    position: absolute;
    font-size: 12px;
    line-height: 1.1;
}
.login-sec .form-area form .form-group .form-control::placeholder{
    font-size: 15px;
    color: #A9A9A9;
}
.login-sec .form-area form button{
    color: #ffffff;
    width: 100%;
    background: #3969D5;
    min-height: 40px;
    margin-top: 30px;
}
.login-sec .form-area button.secondary:hover{
    background: #3969d5!important;
    color: #ffffff;
    border: none!important;
}
.login-sec .form-area button.secondary:hover a{
    color: #ffffff!important;
}
.field-error{
    color: red;
    position: absolute;
    font-size: 12px;
}
form .forgot-password{
    margin: 0;
    position: absolute;
    line-height: 1;
    top: -15px;
    right: 0px;
    font-size: 12px;
    cursor: pointer;
}
.login-sec .forgot-btn{
    color: #3969D5;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 10px 0;
}
.login-sec .forgot-btn small{
    color: #adadad;
}
.login-sec .forgot-btn a{
    text-decoration: none;
}
.forgot-modal.modal.show .modal-dialog.modal-dialog-centered{
    max-width: 600px!important;
}
.forgot-modal.modal.show .modal-dialog.modal-dialog-centered .modal-content{
    border-radius: 15px;
}
.forgot-modal .modal-header{
    border: none;
}
.forgot-modal .modal-body{
    padding: 50px;
}
.forgot-modal .modal-body .forgot-content{
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed var(--sharp-border-color);
}     
.forgot-modal .modal-body .forgot-content h3{
    font-size: 36px;
    color: #3969D5;
    margin: 10px auto;
}
.forgot-modal .modal-body .forgot-content p{
    font-size: 12px;
    font-weight: 400; 
    color: #3d3d3d;
    margin: 0;
}
.forgot-modal .form-group label{
    font-size: 12px;
    font-weight: 400; 
    color: #3d3d3d;
    margin: 0;
}
.forgot-modal .form-group input{
    background: #FFFFFF;
    border: 1px solid var(--sharp-border-color);
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
}
.forgot-modal .form-group .field-error{
    position: absolute;
    color: #ff0000;
    font-size: 12px;
}
.forgot-modal .close-subs-view{
    position: absolute;
    right: 15px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: none;
    background: #3969D5;
    color: #fff;
    border-radius: 50%;
    box-shadow: 2px 0 6px #323030;
    z-index: 99;
}
.forgot-modal .close-subs-view svg{
    line-height: 1;
    width: 15px;
    height: 15px;
}
.forgot-modal .forgot-submit-btn{
    display: inline-flex;
    width: 100%;
    margin: 15px auto;
}
.forgot-modal .forgot-submit-btn button.btn-primary{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 400;
    border: 0.25px solid #3969D5;
    padding: 7px 5px;
    background: #3969D5;
    color: #ffffff;
}

.seperator{
    border-color: rgb(57, 109, 213, 1.0);
}