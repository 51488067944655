/***Detail Page***/
.detail .side h2 {
    height: 21px;
    left: 950px;
    top: 215px;
    font-style: normal;
    font-weight: 700;
    font-size: 19.8318px;
    line-height: 106%;
    color: #1d4283;
  }
  .detail .main .detail_heading_latest {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    margin-left: 6px;
    line-height: 125.5%;
    text-transform: capitalize;
    color: #3d3d3d;
    margin-bottom: 12px;
  }
  .detail .main .heading_latest {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125.5%;
    text-transform: capitalize;
    color: #3d3d3d;
  }
  .detail .main .content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    line-height: 26px;
    color: #3d3d3d;
  }
  .detail .main .content p a {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 35px;
    color: #1D4283;
  }
  .detail .research hr,
  .main hr,
  .latest hr {
    background-color: #1485ff;
    margin: 0px;
  }
  .detail .research {
    padding-right: 0px;
  }
  .detail .latest {
    padding-top: 60px;
    padding-right: 0px;
  }
  .detail .researchitem {
    padding: 18px;
  }
  .detail .researchitem .icon {
    position: absolute !important;
    padding: 0px;
  }
  .detail .researchitem .heading_latest {
    padding-left: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 125.5%;
  }
  .luckmini-icon{
    width: 20px;
    height: 23px;
  }
  .detail .researchitem .content {
    padding-left: 50px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.5%;
  }
  .detail .researchitem .calander {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 125.5%;
    color: #1d4283;
  }
  .detailsocialicon{
    display: flex;
    gap: 10px;
  }
  .detailsocialicon a.active {
    background-color: #3b5998;
    color: #fff !important;
  }
  .detailsocialicon a:hover {
    background-color: #1d4283;
    color: #fff !important;
  }
  .detailsocialicon a img {
    filter: brightness(0.5);
  }
  .detail .main .content .detaildownload {
    font-size: 12px;
    padding: 0px 20px;
    background: #1d4283;
    border-radius: 100px;
    border: none;
    margin: 0 17px;
    color: #fff;
  }
  .detail .main .content .detaildownload:hover{
    background: #1f3e74;
  }
  .detail .researchitem .detailheading_latest {
    width: 67%;
    margin-left: 32%;
    font-size: 15px;
    margin-bottom: 6px;
    font-weight: 700;
  }
  .detail .researchitem .detailcontent {
    width: 67%;
    font-size: 13px;
    line-height: 18px;
    margin-left: 32%;
  }
  .postcomment .detailcomment {
    color: #1485ff;
    border-radius: 5px;
    margin-top: 7px;
    border: 1px solid #1485ff;
    background: none;
    padding: 5px 17px;
  }
  .postcomment .detailcomment:hover{
    background-color: #1485ff;
    color: #fff;
  }
  .lucky-strong {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0rem;
    line-height: 45px;
    color: #6c6c6c;
}
.lucky-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0rem;
    line-height: 5px;
    color: #6c6c6c;
}
.listitems ul {
    margin-bottom: 0px;
    float: right;
    padding-left: 0rem;
}
.listitems li {
    list-style: none;
    border: 1px solid #ccc;
    font-size: 10px;
    margin-left: 13px;
    cursor: pointer;
    float: left;
    padding: 4px 9px;
    border-radius: 5px;
    color: #6c757d;
}
.main hr {
    margin-top: 30px;
    margin-bottom: 30px;
}
.socialicon {
    display: contents;
}
.socialicon a {
    margin-right: 8px;
    border-radius: 100px;
    color: #696969;
    font-size: 11px;
    padding: 5px 7px;
    box-shadow: 0px 0.6140350699424744px 6.140350818634033px 0px #0000001a;
  }
  .social-icon:hover {
    background-color: #1d4283;
    color: #fff;
  }
  .social-icon {
    border-radius: 100px;
    color: #696969;
    font-size: 11px;
    padding: 4px 8px;
    box-shadow: 0px 0.6140350699424744px 6.140350818634033px 0px #0000001a;
}
.postcomment h2 {
    font-size: 24px;
}
.postcomment img {
    height: fit-content;
    margin-right: 25px;
    margin-top: 15px;
}
.short_by {
    font-size: 12px;
}
.held_heading_latest{
  font-weight: 600;
  width: 100%;
}
.postcomment textarea {
    border: 1px solid #1485ff;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    width: 100%;
    padding: 18px 25px;
}
.postcomment textarea {
  resize: none;
}
.time_hour {
    font-size: 14px;
}
  @media only screen and (max-width: 550px) {
    .detail {
      padding: 1rem 1rem;
    }
  }